// PDFGeneration.js
import html2pdf from "html2pdf.js";
import ReactDOMServer from "react-dom/server";

const generatePDF = (jsxContent) => {
  const element = document.createElement("div");
  const jsxHtmlString = ReactDOMServer.renderToString(jsxContent);

  // Set innerHTML with the HTML string
  element.innerHTML = jsxHtmlString;

  // Specify A4 page size
  const pdfOptions = {
    margin: 10, // Optional: Set margins
    filename: "totalhours.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };
  html2pdf().from(element).set(pdfOptions).save();
};

export default generatePDF;
