import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash, FaFilePdf } from "react-icons/fa";
import Slider from "react-slick";
import { FaEye } from "react-icons/fa";
import generatePDF from "./PDFscore";
import { ReactDialogBox } from "react-js-dialog-box";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";

export const Incidents = () => {
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [scoreCard, setscoreCard] = useState("");
  const [activeTab, setactiveTab] = useState("home-tab");
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [getincidentData_close, setgetincidentData_close] = useState([]);
  const [getincidentDataLength, setgetincidentDataLength] = useState([]);
  const [getincidentDataLength_close, setgetincidentDataLength_close] =
    useState([]);
  const [ispinnerset, spinnerset] = useState(false);
  const [incident_count, setincident_count] = useState(0);
  const [nearmiss_count, setnearmiss_count] = useState(0);
  const [report_count, setreport_count] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [getincidentData_in, setgetincidentData_in] = useState("");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereother, setwhereother] = useState(false);
  const [isincidentafe, setisincidentafe] = useState(false);
  const [iswitness, setwitness] = useState(false);
  const [closedd, setclosedd] = useState(false);
  const [openn, setopenn] = useState(true);
  useEffect(() => {
    document.title = "Incident Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    getallreports();
    getallreports_1();
    getallreports_2();
    getallreports_3();
    getallreports_5();
  }, [getincidentData_in]);
  function getallreports_5() {
    let getr = {
      table: "report_hazard",
    };
    axios
      .post("https://jlmining.app/getselectCommon_reporthazard", getr, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("ccc");
        console.log(res.data.length);
        setreport_count(res.data.length);
      })
      .catch((err) => {});
  }
  function getallreports_3() {
    let get = {
      table: "nearmiss_report",
    };
    console.log(get);
    axios
      .post("https://jlmining.app/getselectCommon_Count", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("ccc");
        console.log(res.data.length);
        setnearmiss_count(res.data.length);
      })
      .catch((err) => {});
  }
  function getallreports_2() {
    let formdata = {
      table: "incident_report",
      status: "Open",
    };
    axios
      .post("https://jlmining.app/getselectCommon_Count", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("cd");
        console.log(res.data.length);
        setincident_count(res.data.length);
      })
      .catch((err) => {});
  }
  function getallreports_1() {
    let formdataa = {
      table: "incident_report",
      status: "Closed",
    };
    console.log(formdataa);
    axios
      .post("https://jlmining.app/getselectCommon_c", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setgetincidentDataLength_close(res.data.results.length);

        var indata = res.data.results;
        setgetincidentData_close(indata);
      })
      .catch((err) => {});
  }
  function getallreports() {
    let formdata = {
      table: "incident_report",
      status: "Open",
    };
    axios
      .post("https://jlmining.app/getselectCommon_c", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setgetincidentDataLength(res.data.results.length);

        var indata = res.data.results;
        setgetincidentData(indata);
      })
      .catch((err) => {});
  }
  //Get User Time Sheet
  function getdattime(dateString) {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  }
  var settings_incident = {
    dots: false,

    centerMode: true,
    centerPadding: "40px",
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
  };
  const searchincident = (event) => {
    console.log(event.target.value);
    let get = {
      date: event.target.value,
      table: "incident_report",
    };
    spinnerset(true);
    axios
      .post("https://jlmining.app/searchincident", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        spinnerset(false);

        var indata = res.data.results;
        const filteredData = res.data.results.filter(
          (item) => item.status === "Closed"
        );
        const filteredData_op = res.data.results.filter(
          (item) => item.status === "Open"
        );

        setgetincidentData(filteredData_op);
        setgetincidentDataLength(filteredData_op.length);
        setgetincidentData_close(filteredData);
        setgetincidentDataLength_close(filteredData.length);
      })
      .catch((err) => {});
  };
  const unreadincident = () => {
    let get = {
      table: "incident_report",
    };

    axios
      .post("https://jlmining.app/Unreadstatus", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setincident_count(res.data.length);
      })
      .catch((err) => {});
  };
  const unreadmiss = () => {
    let get = {
      table: "nearmiss_report",
    };

    axios
      .post("https://jlmining.app/Unreadstatus", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setnearmiss_count(res.data.length);
      })
      .catch((err) => {});
  };
  const unreport = () => {
    let get = {
      table: "report_hazard",
    };

    axios
      .post("https://jlmining.app/Unreadstatus", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setreport_count(res.data.length);
      })
      .catch((err) => {});
  };
  const closeBox = () => {
    setisOpen(false);
  };
  const opendialog = (id) => {
    console.log(id);
    let formdata = {
      id: id,
    };
    axios
      .post("https://jlmining.app/getincidentdata_single", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];

          setgetincidentData_in(indata);
          if (indata.was_first === "Yes") {
            setwasfirst(true);
          }
          if (indata.other_person_involved === "Yes") {
            setwhereother(true);
          }
          if (indata.witness === "Yes") {
            setwitness(true);
          }
          if (indata.incident_safe === "Yes") {
            setisincidentafe(true);
          }
        }
      })
      .catch((err) => {});
    setisOpen(true);
  };
  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };
  const checkopt = (event) => {
    setactiveTab(event);
    if (event === "home-tab") {
      setopenn(true);
      setclosedd(false);
    } else {
      setopenn(false);
      setclosedd(true);
    }
  };
  const closedstaus = (event) => {
    let formdata = {
      id: event,
    };
    axios
      .post("https://jlmining.app/closedstaus", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        getallreports();
        getallreports_1();
        getallreports_2();
        getallreports_3();
        getallreports_5();
        setisOpen(false);
      })
      .catch((err) => {});
  };
  function getincidentdetal(id) {
    let formdata = {
      id: id,
    };
    axios
      .post("https://jlmining.app/getincidentdata_single", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];

          setgetincidentData_in(indata);
          if (indata.was_first === "Yes") {
            setwasfirst(true);
          }
          if (indata.other_person_involved === "Yes") {
            setwhereother(true);
          }
          if (indata.witness === "Yes") {
            setwitness(true);
          }
          if (indata.incident_safe === "Yes") {
            setisincidentafe(true);
          }
          createpdf();
        }
      })
      .catch((err) => {});
  }
  const downloadpdf = (event) => {
    console.log(getincidentData_in);
    getincidentdetal(event);
  };
  const createpdf = () => {
    const mainpdf = (
      <table style={{ width: "600px", margin: "0 auto" }}>
        <tbody style={{ width: "100%" }}>
          <tr>
            <td style={{ textAlign: "center" }}>
              <div
                style={{
                  backgroundColor: "red",
                  margin: "0 auto",
                  borderBottom: "1px solid gray",
                  padding: "3px 25px",
                  boxShadow: "2px 2px 2px rgba(0,0,0,.3)",
                }}
              >
                <h1 style={{ color: "#fff", fontSize: "1.5rem" }}>
                  Incident Report View
                </h1>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <h2
                style={{
                  color: "#000",
                  fontSize: "30px",
                  textAlign: "start",
                  margin: "0px",
                  padding: "1rem 0px",
                }}
              >
                Injured person
              </h2>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Name
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.full_name}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Occupation
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.occupation}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Email
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.email_step2}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Telephone number
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.phone_number_step2}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h2
                style={{
                  color: "#000",
                  fontSize: "30px",
                  textAlign: "start",
                  margin: "0px",
                  padding: "1rem 0px",
                }}
              >
                Reporter
              </h2>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Name
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.employee_name}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Address
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.address1_step3}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Occupation
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.occupation_step3}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Email
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.email_step3}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Telephone number
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.phone_step3}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h2
                style={{
                  color: "#000",
                  fontSize: "30px",
                  textAlign: "start",
                  margin: "0px",
                  padding: "1rem 0px",
                }}
              >
                Details
              </h2>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Date and time
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.step4_date
                  ? getdattime(getincidentData_in.step4_date)
                  : ""}

                {getincidentData_in.time_zone}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Site
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.site_step4}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Site reference
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.site_reference}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Location
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.locations}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Description
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.chain_event}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Was first aid given
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                abc
              </p>
            </td>
          </tr>
          {iswasfirst && (
            <tr>
              <td>
                <h3
                  style={{
                    color: "#000",
                    margin: "0px",
                    paddingTop: "10px",
                    display: "block",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Treatment
                </h3>
              </td>
            </tr>
          )}
          {iswasfirst && (
            <tr>
              <td>
                <p
                  style={{
                    color: "#4f4f4f",
                    fontWeight: 500,
                    margin: "0px",
                    fontSize: "14px",
                  }}
                >
                  {getincidentData_in.treatment}
                </p>
              </td>
            </tr>
          )}
          {iswasfirst && (
            <tr>
              <td>
                <h3
                  style={{
                    color: "#000",
                    margin: "0px",
                    paddingTop: "10px",
                    display: "block",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Name of first aider
                </h3>
              </td>
            </tr>
          )}
          {iswasfirst && (
            <tr>
              <td>
                <p
                  style={{
                    color: "#4f4f4f",
                    fontWeight: 500,
                    margin: "0px",
                    fontSize: "14px",
                  }}
                >
                  {getincidentData_in.first_aider}
                </p>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Were there any other person/s involved in the incident?
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.other_person_involved}
              </p>
            </td>
          </tr>
          {iswhereother && (
            <tr>
              <td>
                <h3
                  style={{
                    color: "#000",
                    margin: "0px",
                    paddingTop: "10px",
                    display: "block",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Details of other person/s involved
                </h3>
              </td>
            </tr>
          )}
          {iswhereother && (
            <tr>
              <td>
                <p
                  style={{
                    color: "#4f4f4f",
                    fontWeight: 500,
                    margin: "0px",
                    fontSize: "14px",
                  }}
                >
                  {getincidentData_in.detail_person_involved}
                </p>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Were there any witnesses to the incident?
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.witness}
              </p>
            </td>
          </tr>
          {iswitness && (
            <tr>
              <td>
                <h3
                  style={{
                    color: "#000",
                    margin: "0px",
                    paddingTop: "10px",
                    display: "block",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Include name and contact number
                </h3>
              </td>
            </tr>
          )}
          {iswitness && (
            <tr>
              <td>
                <p
                  style={{
                    color: "#4f4f4f",
                    fontWeight: 500,
                    margin: "0px",
                    fontSize: "14px",
                  }}
                >
                  {getincidentData_in.detail_witness}
                </p>
              </td>
            </tr>
          )}
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Has the incident site been made safe?
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {getincidentData_in.incident_safe}
              </p>
            </td>
          </tr>
          {isincidentafe && (
            <tr>
              <td>
                <h3
                  style={{
                    color: "#000",
                    margin: "0px",
                    paddingTop: "10px",
                    display: "block",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Details of actions taken
                </h3>
              </td>
            </tr>
          )}
          {isincidentafe && (
            <tr>
              <td>
                <p
                  style={{
                    color: "#4f4f4f",
                    fontWeight: 500,
                    margin: "0px",
                    fontSize: "14px",
                  }}
                >
                  {getincidentData_in.action_token}
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
    const filenamee = "Incident Report";
    setTimeout(() => {
      generatePDF(mainpdf, filenamee);
    }, 500);
  };
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          margin-top:60px;
          z-index:99;

        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Action Center Page</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/admin/actioncenter/incidents"
                          className="text-danger"
                          onClick={unreadincident}
                        >
                          Incidents
                        </Link>
                        <Link
                          to="/admin/actioncenter/incidents"
                          onClick={unreadincident}
                          className="text-danger"
                        >
                          {incident_count}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/admin/actioncenter/near_miss"
                          onClick={unreadmiss}
                        >
                          Near Misses
                        </Link>
                        <Link
                          to="/admin/actioncenter/near_miss"
                          onClick={unreadmiss}
                          className="text-danger"
                        >
                          {nearmiss_count}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/admin/actioncenter/reporthazard"
                          onClick={unreport}
                        >
                          Report a Hazard
                        </Link>
                        <Link
                          to="/admin/actioncenter/reporthazard"
                          onClick={unreport}
                          className="text-danger"
                        >
                          {report_count}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isOpen && (
                <>
                  <ReactDialogBox
                    closeBox={closeBox}
                    modalWidth="58%"
                    headerBackgroundColor="red"
                    headerTextColor="white"
                    headerHeight="50"
                    closeButtonColor="white"
                    bodyBackgroundColor="white"
                    bodyTextColor="black"
                    bodyHeight="500px"
                    headerText="Incident Report View"
                  >
                    <div style={{ height: "400px", overflowY: "scroll" }}>
                      <h1 className="text-dark">Incident Record</h1>
                      <div className="d-flex flex-column gap-3 bg-white shadow  p-4 inperson">
                        <h3>Injured person</h3>
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex flex-column ">
                            <label for="">Name</label>
                            <h6>{getincidentData_in.full_name}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Address</label>
                            <h6>{getincidentData_in.address1}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Occupation</label>
                            <h6>{getincidentData_in.occupation}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Email</label>
                            <h6>{getincidentData_in.email_step2}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Telephone number</label>
                            <h6>{getincidentData_in.phone_number_step2}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3 bg-white shadow  p-4 inperson">
                        <h3>Reporter</h3>
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex flex-column ">
                            <label for="">Name</label>
                            <h6>{getincidentData_in.employee_name}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Address</label>
                            <h6>{getincidentData_in.address1_step3}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Occupation</label>
                            <h6>{getincidentData_in.occupation_step3}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Email</label>
                            <h6>{getincidentData_in.email_step3}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Telephone number</label>
                            <h6>{getincidentData_in.phone_step3}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3 bg-white shadow  p-4 inperson">
                        <h3>Details</h3>
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex flex-column ">
                            <label for="">Date and time</label>
                            <h6>
                              {getincidentData_in.step4_date
                                ? getdattime(getincidentData_in.step4_date)
                                : ""}

                              {getincidentData_in.time_zone}
                            </h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Site</label>
                            <h6>{getincidentData_in.site_step4}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Site reference</label>
                            <h6>{getincidentData_in.site_reference}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Location</label>
                            <h6>{getincidentData_in.locations}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Description</label>
                            <h6>{getincidentData_in.chain_event}</h6>
                          </div>
                          <div className="d-flex flex-column ">
                            <label for="">Was first aid given</label>
                            <h6>{getincidentData_in.was_first}</h6>
                          </div>
                          {iswasfirst && (
                            <div className="d-flex flex-column ">
                              <label for="">Treatment</label>
                              <h6>{getincidentData_in.treatment}</h6>
                            </div>
                          )}
                          {iswasfirst && (
                            <div className="d-flex flex-column ">
                              <label for="">Name of first aider</label>
                              <h6>{getincidentData_in.first_aider}</h6>
                            </div>
                          )}
                          <div className="d-flex flex-column ">
                            <label for="">
                              Were there any other person/s involved in the
                              incident?
                            </label>
                            <h6>{getincidentData_in.other_person_involved}</h6>
                          </div>
                          {iswhereother && (
                            <div className="d-flex flex-column ">
                              <label for="">
                                Details of other person/s involved
                              </label>
                              <h6>
                                {getincidentData_in.detail_person_involved}
                              </h6>
                            </div>
                          )}
                          <div className="d-flex flex-column ">
                            <label for="">
                              Were there any witnesses to the incident?
                            </label>
                            <h6>{getincidentData_in.witness}</h6>
                          </div>
                          {iswitness && (
                            <div className="d-flex flex-column ">
                              <label for="">
                                Include name and contact number
                              </label>
                              <h6>{getincidentData_in.detail_witness}</h6>
                            </div>
                          )}
                          <div className="d-flex flex-column ">
                            <label for="">
                              Has the incident site been made safe?
                            </label>
                            <h6>{getincidentData_in.incident_safe}</h6>
                          </div>
                          {isincidentafe && (
                            <div className="d-flex flex-column ">
                              <label for="">Details of actions taken</label>
                              <h6>{getincidentData_in.action_token}</h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {getincidentData_in.status === "Open" && (
                      <div className="mt-4">
                        <Link
                          to="javascript:void(0)"
                          onClick={() => closedstaus(getincidentData_in.id)}
                          className="cbtn"
                        >
                          Closed
                        </Link>
                        .
                      </div>
                    )}
                  </ReactDialogBox>
                </>
              )}
              <div className="col-md-8">
                <div className="right-form-box bg-white shadow p-2">
                  <div className="d-flex">
                    <div className="col-md-6">
                      <label>Date range</label>
                      <br />
                      <input
                        type="date"
                        class="serch"
                        name="daterange"
                        onChange={searchincident}
                        placeholder="Search Industries"
                      />
                      {ispinnerset && (
                        <span className="spinner-border text-success mb-2"></span>
                      )}
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <ul
                        className="nav nav-tabs inci-tab"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              activeTab === "home-tab" ? "active" : ""
                            }`}
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected="true"
                            onClick={() => checkopt("home-tab")}
                          >
                            Open ({getincidentDataLength})
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              activeTab === "closed-tab" ? "active" : ""
                            }`}
                            id="closed-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#closed-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="closed-tab-pane"
                            aria-selected="true"
                            onClick={() => checkopt("closed-tab")}
                          >
                            Closed ({getincidentDataLength_close})
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {openn && (
                    <div className="row">
                      {getincidentData.length > 0 ? (
                        <div className="row">
                          {getincidentData.map((item, index) => (
                            <div className="col-md-6 mt-2  gap-2">
                              <div className="test position-relative reph">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-3">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <h4>{item.reference}</h4>
                                      </div>
                                      <div className="col-md-2">
                                        <Link
                                          to="javascript:void(0)"
                                          className="text-danger"
                                          onClick={() => opendialog(item.id)}
                                          style={{ fontSize: "20px" }}
                                        >
                                          <FaEye />
                                        </Link>
                                      </div>
                                      <div className="col-md-2">
                                        <Link
                                          to="javascript:void(0)"
                                          className="text-danger"
                                          onClick={() => downloadpdf(item.id)}
                                          style={{ fontSize: "20px" }}
                                        >
                                          <FaFilePdf />
                                        </Link>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 test-info">
                                      <p>
                                        <strong>Site:</strong>{" "}
                                        {item.hazard_site}
                                      </p>
                                      <p>
                                        <strong>Location:</strong>{" "}
                                        {item.locations}
                                      </p>

                                      <p>
                                        <strong>Status:</strong> {item.status}
                                      </p>
                                      <p>
                                        <strong>Created date:</strong>{" "}
                                        {getdattime(item.created_at)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div> No data available </div>
                      )}
                    </div>
                  )}

                  {closedd && (
                    <div className="row">
                      {getincidentData_close.length > 0 ? (
                        <div className="row">
                          {getincidentData_close.map((item, index) => (
                            <div className="col-md-6 mt-2  gap-2">
                              <div className="test position-relative reph">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-3">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <h4>{item.reference}</h4>
                                      </div>
                                      <div className="col-md-2">
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={() => opendialog(item.id)}
                                          style={{ fontSize: "20px" }}
                                        >
                                          <FaEye />
                                        </Link>
                                      </div>
                                      <div className="col-md-2">
                                        <Link
                                          to="javascript:void(0)"
                                          onClick={() => downloadpdf(item.id)}
                                          style={{ fontSize: "20px" }}
                                        >
                                          <FaFilePdf />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column gap-2 test-info">
                                      <p>
                                        <strong>Site:</strong>{" "}
                                        {item.hazard_site}
                                      </p>
                                      <p>
                                        <strong>Location:</strong>{" "}
                                        {item.locations}
                                      </p>

                                      <p>
                                        <strong>Status:</strong> {item.status}
                                      </p>
                                      <p>
                                        <strong>Created date:</strong>{" "}
                                        {getdattime(item.created_at)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div> No data available </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
