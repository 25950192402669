import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import Select from "react-select";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash, FaFilePdf } from "react-icons/fa";
import generatePDF from "./PDFscore";
import { ReactDialogBox } from "react-js-dialog-box";
import Slider from "react-slick";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";
import { FaEdit } from "react-icons/fa";
export const Reporthazards = () => {
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [hazardreport, sethazardreport] = useState([]);
  const [hazardreportvl, sethazardreportvl] = useState("");
  const [issetSelectedhazard, setSelectedhazard] = useState("");
  const [issethazatdData, sethazatdData] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [incident_count, setincident_count] = useState(0);
  const [nearmiss_count, setnearmiss_count] = useState(0);
  const [report_count, setreport_count] = useState(0);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [issetgetLocationsingle, setgetLocationsingle] = useState("");
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [getincidentDataLength, setgetincidentDataLength] = useState([]);
  const [ispinnerset, spinnerset] = useState(false);
  const [ishazard_id, setishazard_id] = useState("");
  const [setmessage, setsetmessage] = useState(false);
  useEffect(() => {
    document.title = "Report Hazard Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      table: "report_hazard",
    };
    axios
      .post("https://jlmining.app/getselectCommonForhazard", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setgetincidentDataLength(res.data.results.length);
        if (res.data.results.length > 0) {
          var indata = res.data.results;
          setgetincidentData(indata);
        }
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        const options = [];
        if (res.data.results.length > 0) {
          options.push({ value: "All", label: "All" });
          // Add as many values or labels as needed

          // Now map res.data.results and add them to the array
          const mappedOptions = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));

          // Concatenate the manually added options with the mapped options
          const allOptions = options.concat(mappedOptions);
          setgetLocations(allOptions);
        }
      })
      .catch((err) => {});
    let get = {
      table: "nearmiss_report",
    };
    axios
      .post("https://jlmining.app/getselectCommon_Count", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setnearmiss_count(res.data.length);
      })
      .catch((err) => {});
    let formdataa = {
      table: "incident_report",
    };
    axios
      .post("https://jlmining.app/getselectCommon_Count", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setincident_count(res.data.length);
      })
      .catch((err) => {});
    let formdataaa = {
      table: "report_hazard",
    };
    axios
      .post("https://jlmining.app/getselectCommon_reporthazard", formdataaa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setreport_count(res.data.length);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getalluseradmin", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value:
              item.first_name + " " + item.middle_name + " " + item.last_name,
            label:
              item.first_name + " " + item.middle_name + " " + item.last_name,
          }));
          sethazardreport(options);
        }
      })
      .catch((err) => {});
  }, [issethazatdData]);

  //Get User Time Sheet
  function getdattime(dateString) {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  }
  var settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "40px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };
  const searchincident = (event) => {
    console.log(event.target.value);
    let get = {
      date: event.target.value,
    };
    spinnerset(true);
    axios
      .post("https://jlmining.app/searchdatehazard", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        spinnerset(false);
        setgetincidentDataLength(res.data.results.length);
        var indata = res.data.results;
        setgetincidentData(indata);
      })
      .catch((err) => {});
  };
  const searchhazad = (event) => {
    setgetLocationsingle(event.value);
    if (event.value === "All") {
      var vl = "";
    } else {
      var vl = event.value;
    }
    let get = {
      location: vl,
      table: "report_hazard",
    };
    spinnerset(true);
    axios
      .post("https://jlmining.app/searchhazard", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        spinnerset(false);
        setgetincidentDataLength(res.data.results.length);

        var indata = res.data.results;
        setgetincidentData(indata);
      })
      .catch((err) => {});
  };
  const stepsite = (event) => {
    console.log(event.value);
    if (event !== "") {
      setSelectedhazard({
        value: event.value,
        label: event.value,
      });
    }
  };
  const unreadincident = () => {
    let get = {
      table: "incident_report",
    };

    axios
      .post("https://jlmining.app/Unreadstatus", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setincident_count(res.data.length);
      })
      .catch((err) => {});
  };
  const unreadmiss = () => {
    let get = {
      table: "nearmiss_report",
    };

    axios
      .post("https://jlmining.app/Unreadstatus", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setnearmiss_count(res.data.length);
      })
      .catch((err) => {});
  };
  const unreport = () => {
    let get = {
      table: "report_hazard",
    };

    axios
      .post("https://jlmining.app/Unreadstatus", get, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setnearmiss_count(res.data.length);
      })
      .catch((err) => {});
  };
  const opendialog = (id) => {
    let formdata = {
      id: id,
    };
    axios
      .post("https://jlmining.app/getsinglehazrad", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          var indata = res.data.row[0];
          sethazatdData(indata);
          setSelectedhazard({
            value: indata.hazard_site,
            label: indata.hazard_site,
          });
          sethazardreportvl({
            value: indata.reporter,
            label: indata.reporter,
          });
          setishazard_id(indata.id);
        }
      })
      .catch((err) => {});
    setisOpen(true);
  };
  function getincidentdetal(id) {
    let formdata = {
      id: id,
    };
    axios
      .post("https://jlmining.app/getsinglehazrad", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          var indata = res.data.row[0];
          sethazatdData(indata);
          setSelectedhazard({
            value: indata.hazard_site,
            label: indata.hazard_site,
          });
          sethazardreportvl({
            value: indata.reporter,
            label: indata.reporter,
          });
          setishazard_id(indata.id);
          createpdf();
        }
      })
      .catch((err) => {});
  }
  const downloadpdf = (event) => {
    getincidentdetal(event);
    console.log(issethazatdData);
  };
  const createpdf = () => {
    const mainpdf = (
      <table style={{ width: "600px", margin: "0 auto" }}>
        <tbody style={{ width: "100%" }}>
          <tr>
            <td style={{ textAlign: "center" }}>
              <div
                style={{
                  backgroundColor: "red",
                  margin: "0 auto",
                  borderBottom: "1px solid gray",
                  padding: "3px 25px",
                  boxShadow: "2px 2px 2px rgba(0,0,0,.3)",
                }}
              >
                <h1 style={{ color: "#fff", fontSize: "1.5rem" }}>
                  Report hazard View
                </h1>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Hazard name
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {issethazatdData.name}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Hazard site
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {issetSelectedhazard.value}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  display: "block",
                  paddingTop: "10px",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Hazard location
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {issethazatdData.location}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Hazard reporter
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {hazardreportvl.value}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h3
                style={{
                  color: "#000",
                  margin: "0px",
                  paddingTop: "10px",
                  display: "block",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Hazard details
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  color: "#4f4f4f",
                  fontWeight: 500,
                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                {issethazatdData.details}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
    const filenamee = "Report Hazard";
    setTimeout(() => {
      generatePDF(mainpdf, filenamee);
    }, 500);
  };
  const closeBox = () => {
    setisOpen(false);
  };
  const hazardrep = (event) => {
    console.log(event.value);
    if (event !== "") {
      sethazardreportvl({
        value: event.value,
        label: event.value,
      });
    }
  };
  const handsubmit = (event) => {
    var vll = event.target;
    const formData = new FormData();

    formData.append("id", ishazard_id);
    formData.append("name", vll.name.value);
    formData.append("hazard_site", vll.hazard_site.value);
    formData.append("location", vll.location.value);
    formData.append("details", vll.details.value);
    formData.append("hazard_id", vll.hazard_id.value);

    axios
      .post("https://jlmining.app/reporthazardUp", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        event.target.reset();
        console.log(res.data.row.length);
        if (res.data.row.length > 0) {
          let formdata = {
            table: "report_hazard",
          };
          axios
            .post("https://jlmining.app/getselectCommonForhazard", formdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setgetincidentDataLength(res.data.results.length);
              if (res.data.results.length > 0) {
                var indata = res.data.results;
                setgetincidentData(indata);
              }
            })
            .catch((err) => {});
          setsetmessage(true);

          setTimeout(function () {
            setisOpen(false);
            setsetmessage(false);
          }, 4500);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          margin-top:15px;
          z-index:99;

        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Action Center Page</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/admin/actioncenter/incidents"
                          onClick={unreadincident}
                        >
                          Incidents
                        </Link>
                        <Link
                          to="/admin/actioncenter/incidents"
                          onClick={unreadincident}
                          className="text-danger"
                        >
                          {incident_count}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/admin/actioncenter/near_miss"
                          onClick={unreadmiss}
                        >
                          Near Misses
                        </Link>
                        <Link
                          to="/admin/actioncenter/near_miss"
                          onClick={unreadmiss}
                          className="text-danger"
                        >
                          {nearmiss_count}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/admin/actioncenter/reporthazard"
                          onClick={unreport}
                          className="text-danger"
                        >
                          Report a Hazard
                        </Link>
                        <Link
                          to="/admin/actioncenter/reporthazard"
                          className="text-danger"
                          onClick={unreport}
                        >
                          {report_count}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isOpen && (
                <>
                  <ReactDialogBox
                    closeBox={closeBox}
                    modalWidth="28%"
                    headerBackgroundColor="red"
                    headerTextColor="white"
                    headerHeight="50"
                    closeButtonColor="white"
                    bodyBackgroundColor="white"
                    bodyTextColor="black"
                    headerText="View Report a Hazard"
                  >
                    <div>
                      <form
                        action="javascript:void(0)"
                        method="post"
                        className="mt-3 pt-4"
                      >
                        <div className="d-flex flex-column flex-md-row gap-3 justify-content-between align-items-center hazard-title mb-4">
                          <div>
                            <h3></h3>
                          </div>
                        </div>

                        <input
                          type="hidden"
                          value={issethazatdData.id}
                          name="hazard_id"
                        />

                        <div className="row mt-4">
                          <div className="col-md-5 hazard-form-block">
                            <div className="d-flex flex-column gap-2">
                              <div className="d-flex flex-column gap-2">
                                <label for="">Hazard name</label>
                                <h6>{issethazatdData.name}</h6>
                              </div>
                              <div className="d-flex flex-column gap-2 ">
                                <label for="">Hazard site</label>
                                <h6>{issetSelectedhazard.value}</h6>
                              </div>
                              <div className="d-flex flex-column gap-2">
                                <label for="">Hazard location</label>
                                <h6>{issethazatdData.location}</h6>
                              </div>
                              <div className="d-flex flex-column gap-2">
                                <label for="">Hazard reporter</label>
                                <h6>{hazardreportvl.value}</h6>
                              </div>
                              <div className="d-flex flex-column gap-2">
                                <label for="">Hazard details</label>
                                <h6>{issethazatdData.details}</h6>
                              </div>

                              <div className="d-flex gap-2 align-items-center buttons-two">
                                <button
                                  type="button"
                                  onClick={closeBox}
                                  className="rep-btn"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </ReactDialogBox>
                </>
              )}
              <div className="col-md-8">
                <div className="right-form-box bg-white shadow p-2">
                  <div className="d-flex">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Site</label>

                        <Select
                          options={issetgetLocations}
                          name="form_site"
                          className="mt-2"
                          value={issetgetLocationsingle}
                          onChange={searchhazad}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Date range</label>
                        <input
                          type="date"
                          className="serch"
                          name="daterange"
                          onChange={searchincident}
                          placeholder="Search Industries"
                        />
                        {ispinnerset && (
                          <span className="spinner-border text-success mb-2"></span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {getincidentData.length > 0 ? (
                      <div className="row">
                        {getincidentData.map((item, index) => (
                          <div className="col-md-6 mt-2  gap-2">
                            <div className="test position-relative reph">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column gap-3">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <h4>{item.name}</h4>
                                    </div>
                                    <div className="col-md-2">
                                      <Link
                                        className="text-danger"
                                        to="javascript:void(0)"
                                        onClick={() => opendialog(item.id)}
                                        style={{ fontSize: "20px" }}
                                      >
                                        <FaEdit />
                                      </Link>
                                    </div>
                                    <div className="col-md-2">
                                      <Link
                                        to="javascript:void(0)"
                                        className="text-danger"
                                        onClick={() => downloadpdf(item.id)}
                                        style={{ fontSize: "20px" }}
                                      >
                                        <FaFilePdf />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column gap-2 test-info">
                                    <p>
                                      <strong>Site:</strong> {item.hazard_site}
                                    </p>
                                    <p>
                                      <strong>Created date:</strong>{" "}
                                      {getdattime(item.created_at)}
                                    </p>
                                    <p>
                                      <strong>Reporter:</strong> {item.reporter}
                                    </p>
                                    <p>
                                      <strong>Owner:</strong> Unassigned
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div> No data available </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
