import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useKeyboardShortcutBlocker from "./KeyboardShortcutBlocker";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "./assets/banner/scss/defult.css";
import "./assets/banner/scss/main.css";
export const Client = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  //useKeyboardShortcutBlocker();

  const audioRef = useRef(null); // Ref for the audio element

  // Function to handle user input changes

  // Function to start recording audio

  // Function to stop recording audio
  const [checklogin, setchecklogin] = useState(false);
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Jlmining Page";
    if (userStr !== null) {
      setchecklogin(true);
      navigate("/home");
    }
  }, [checklogin]);
  var settings_2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md bg-body-tertiary">
          <div className="container-xl">
            <a className="navbar-branda" href="#">
              <img
                className="w-100 h-100"
                src={require("./assets/banner/images/logo.png")}
                alt="Logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <div className="d-flex flex-md-row flex-column py-md-0 py-4 ms-auto gap-4 login_btn">
                <a className href="/login">
                  Login
                </a>
                <a href="/register">Register</a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section className="d-block chosse_banner">
        <div className="clip_left"></div>
        <div className="clip_right"></div>
        <div className="d-flex flex-column justify-content-center position-relative align-items-center h-100">
          <h3>Why You choose us</h3>
        </div>
      </section>
      <section className="d-block py-5 discover">
        <div className="container-lg">
          <div className="d-flex flex-column gap-3">
            <h4>Discover the Perfect Employees for Your Business with Us</h4>

            <p>
              <span>
                At Jl Mining , we're dedicated to connecting you with the best
                talent for your business needs. As an equal opportunity
                provider, we are passionate about matching the right person to
                the right position, ensuring that every candidate has the
                opportunity to find meaningful Employment.
              </span>
            </p>
            <p>
              <span>
                We recognize the challenges of unemployment and the toll it can
                take on both financial stability and mental health. That’s why
                we strive tirelessly to get our candidates back into the
                workforce, offering resources to support their overall
                well-being, because everyone deserves a fulfilling and happy
                life.
              </span>
            </p>
            <p>
              Tailored Solutions for Your Business
              <br />
              JL Mining is the premier choice for the mining, civil, and
              construction industries, delivering specialized staffing
              solutions. We offer customized recruitment services, regardless of
              the job size. Our industry-leading practices ensure that only the
              most skilled and suitable employees join your team, providing the
              quality and performance you need on the frontline.
            </p>
          </div>
        </div>
      </section>
      <section className="d-block py-5 business">
        <div className="container-lg">
          <div className="row gy-5">
            <div className="col-12">
              <div className="d-block pb-3">
                <h4>
                  Discover the Perfect Employees for Your Business with Us
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column text-center justify-content-center align-items-center gap-3 bus-box">
                <div className="w-cicle">
                  <div className="d-block icon_fitw">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/australia.png")}
                      alt="image"
                    />
                  </div>
                </div>

                <h5>Australia wide Reach</h5>
                <p>
                  We are proud to be Australia-wide suppliers to some of the
                  nation's largest engineering-based employers and have been
                  integral to many of Australia's most iconic engineering
                  projects. Our partnership and expertise in capital and
                  large-scale projects and assets distinguish us as the premier
                  staffing partner in this field.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column text-center justify-content-center align-items-center gap-3 bus-box">
                <div className="w-cicle">
                  <div className="d-block icon_fitw">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/experience.png")}
                      alt="image"
                    />
                  </div>
                </div>

                <h5>Years of Mining Experience</h5>
                <p>
                  For over years, we have been providing comprehensive
                  contractor management services, blending global expertise with
                  local insights. Our team is devoted to the mining industry,
                  having built an extensive database of mining talent, many of
                  whom have successfully worked with us before.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column text-center justify-content-center align-items-center gap-3 bus-box">
                <div className="w-cicle">
                  <div className="d-block icon_fitw">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/service.png")}
                      alt="image"
                    />
                  </div>
                </div>

                <h5>A comprehensive service</h5>
                <p>
                  Our customized portal streamlines time management, client
                  verification, and employee payroll, while offering essential
                  information, training, and safety reporting to ensure a
                  seamless and efficient workforce experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5 professionals">
        <div className="container-lg">
          <div className="row gy-5">
            <div className="col-12">
              <div className="d-flex flex-column gap-3 text-center justify-content-center align-items-center">
                <h4>
                  Supplying Tomorrow’s Talent <span>Today</span>
                </h4>
                <p>
                  Our highly skilled and motivated employees bring a diverse
                  range of expertise, including:
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="professionals_list">
                <div className="pro1 pro_text bgsky_blue">
                  <p>Engineering</p>
                </div>
                <div className="pro2 pro_text bgsky_blue">
                  <p>Processing & Metallurgy</p>
                </div>
                <div className="pro3 pro_text">
                  <p>Pipefitters</p>
                </div>
                <div className="pro4 pro_text">
                  <p>Pipefitters</p>
                </div>
                <div className="pro5 pro_text bgsky_blue">
                  <p>Construction & Commissioning</p>
                </div>
                <div className="pro6 pro_text bgsky_blue">
                  <p>Engineering</p>
                </div>
                <div className="pro7 pro_text bgsky_blue">
                  <p>Scaffolders</p>
                </div>
                <div className="pro8 pro_text">
                  <p>Scaffolders</p>
                </div>
                <div className="pro9 pro_text">
                  <p>Site Supervisors</p>
                </div>
                <div className="pro10 pro_text">
                  <p>Project managers</p>
                </div>
                <div className="pro11 pro_text">
                  <p>Engineering</p>
                </div>
                <div className="pro12 pro_text">
                  <p>Engineering</p>
                </div>
                <div className="pro13 pro_text">
                  <p>Crane Operators</p>
                </div>
                <div className="pro14 pro_text">
                  <p>Riggers</p>
                </div>
                <div className="pro15 pro_text">
                  <p>Truck Drivers</p>
                </div>
                <div className="pro16 pro_text">
                  <p>Truck Drivers</p>
                </div>
                <div className="pro17 pro_text bgsky_blue">
                  <p>Operations & Maintenance</p>
                </div>
                <div className="pro18 pro_text bgsky_blue">
                  <p>Project Services and Safety</p>
                </div>
                <div className="pro19 pro_text bgsky_blue">
                  <p>Electricians</p>
                </div>
                <div className="pro20 pro_text">
                  <p>Electricians</p>
                </div>
                <div className="pro21 pro_text bgsky_blue">
                  <p>Engineering</p>
                </div>
                <div className="pro22 pro_text bgsky_blue">
                  <p>Processing & Metallurgy</p>
                </div>
                <div className="pro23 pro_text bgsky_blue">
                  <p>Pipefitters</p>
                </div>
                <div className="pro24 pro_text">
                  <p>Pipefitters</p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-center align-items-center">
                <a className="gettouch" href="#">
                  Get In Touch for Mining Staffing Support
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5 employees">
        <div className="container-lg">
          <div className="row gy-3 ">
            <div className="col-12">
              <div className="d-block pb-3">
                <h4>What We Provide to Our Employees</h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-block">
                <img
                  className="w-100 h-100"
                  src={require("./assets/banner/images/w-men.png")}
                  alt="images"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column gap-3 justify-content-center align-items-center h-100 mt-0 mt-md-4">
                <h5>
                  Efficient Workforce Management Through Our Customized Portal
                </h5>
                <p>
                  We provide a Digital portal for employees, offering a range of
                  features to streamline their work experience:
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block pb-5 feature_client">
        <div className="container-lg">
          <div className="row gy-3 ">
            <div className="col-12">
              <div className="d-block py-3">
                <h4>Features</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/time.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Time Management:</h6>
                  <p>
                    Employees can adjust and arrange dates in their timesheets
                    and submit work hours directly through the portal.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/client1.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Client Verification:</h6>
                  <p>
                    Clients can view and verify employee hours, and provide
                    signatures, ensuring the management at JL Mining knows that
                    hours are authenticated as per the portal’s records.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/patroll.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Payroll:</h6>
                  <p>
                    Based on the verified hours, we handle employee payments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/access.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Access to Information:</h6>
                  <p>
                    Employees can access on-site documents, view machinery
                    manuals, and obtain client details and location information,
                    including emergency contacts and the nearest town.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/training.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Training:</h6>
                  <p>
                    Online training is available within the portal, allowing
                    employees to earn certificates that ease their workplace
                    integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/safety.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Safety Reporting:</h6>
                  <p>
                    The portal supports the reporting of hazards, incidents, and
                    near-misses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5 our-partner1">
        <div className="container-lg">
          <div className="d-flex flex-column gap-2 pb-3">
            <h4>Our Trusted Partners</h4>
            <p>
              We deliver great results and we have cultivated long-term
              relationships with leading brands and companies.
            </p>
          </div>
          <Slider {...settings_2} className="logo-slider ">
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-hss.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-bird.webp")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/jl-logo.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-hunta.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img-h d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-minotaur.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-walc.png")}
                alt="logo"
              />
            </div>
          </Slider>
        </div>
      </section>
      <section className="d-block pb-5 get_intouch">
        <div className="d-flex flex-column justify-content-center align-items-center gap-3 py-4 bg-get_intouch text-center">
          <h4>
            Staffing Excellence for a<br />
            <span>Brighter Future</span>
          </h4>
          <a href="#">Get In Touch</a>
          <div className="clip_left"></div>
          <div className="clip_right"></div>
        </div>
      </section>
    </>
  );
};
